$(document).ready(initPage);

function initPage(){
  if($('.page-home').length) {
    let scrollpos = $(window).scrollTop();
    $('#logo').css('top', 144 - $(this).scrollTop() + "px");
    $(window).scroll(function(){
      if ( $(this).scrollTop() < 144 ){
        $('#logo').css('top', 144 - $(this).scrollTop() + "px");
        $('body').removeClass('fixed-header');
      } else {
        $('body').addClass('fixed-header');
      }
    })
  } else {
    $(window).scroll(function(){
      ($(this).scrollTop() > 0) ? $('body').addClass('fixed-header') : $('body').removeClass('fixed-header');
    })
  }

  $('.toggler').click(function () {
    $('.header-nav').toggleClass('active');
    if($('.header-nav').hasClass('active')) {
      $('body').addClass('fixed-header-const');
    } else {
      $('body').removeClass('fixed-header-const');
    }
  })

  const testimonialsSwiper = new Swiper('.testimonials-swiper-container', {
    loop: true,
    spaceBetween: 36,
    slidesPerView: 2,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 'auto',
        spaceBetween: 20
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 36
      }
    }
  });

  if($('.video-link').length) {
    $('.video-link').magnificPopup({
      type:'iframe',
      fixedContentPos: false
    });
  }

  if($('.custom-radio-lg').length) {
    $('.custom-radio-lg input').change(function(){
      $('.custom-radio-lg input:checked').parent().addClass('checked');
      $('.custom-radio-lg input:checked').parent().siblings().removeClass('checked');
    });
  }

  if($('.custom-checkbox').length) {
    $('.custom-checkbox input').change(function(){
      $(this).parent().toggleClass('checked');
    });
  }

  
  
}